<template>
  <v-container
    style="justify-content: center; display: flex; height: 100%; background: #FFFFFF 0% 0% no-repeat padding-box; opacity: 1;">

    <div class="text-center">

      <b class="valor"> VALOR</b>

      <br>

      <div style="display: flex;">
        <hr style="width: 50px; height: 0px; border: 1px solid #D0D0D0; opacity: 1; margin:auto;"><b class="precio">{{
          formatPrice(priceTotal)
        }}</b>
        <hr style="width: 50px; height: 0px; border: 1px solid #D0D0D0; opacity: 1; margin:auto;">
      </div>

      <br>
      <br>
      <br>

      <template>
        <v-data-table :footer-props="{ 'items-per-page-text': '' }" :headers="headers" :items="patentes"
          :items-per-page="5" class="elevation-1">
          <template v-slot:top>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Estás seguro de eliminar este producto?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Confirmar</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:item.price="{ item }">
            <label style="margin:auto">{{ formatPrice(item.price) }}</label>
          </template>
        </v-data-table>

      </template>

      <br>

      <b class="valor"> ¿Quieres agregar otro vehículo?
      </b>

      <br>
      <br>
      <br>

      <v-row style="justify-content: center;">

        <v-btn
          style="width: 30px; height: 64px; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: 0px 0px 10px #2D387C; border: 1px solid #DEDEDE;
              border-radius: 30px; opacity: 1; text-align: center; font: normal normal normal 22px/27px Helvetica Neue; letter-spacing: 0.66px; color: #3333CC; text-transform: uppercase; opacity: 1; margin-left: -1%;"
          @click="NewCarro()">
          Si
        </v-btn>

        <v-btn v-if="priceTotal != 0"
          style="width: 30px; height: 64px; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: 0px 0px 10px #2D387C; border: 1px solid #DEDEDE; border: 1px solid #DEDEDE;
              border-radius: 30px; opacity: 1; text-align: center; font: normal normal normal 22px/27px Helvetica Neue; letter-spacing: 0.66px; color: #3333CC; text-transform: uppercase; opacity: 1; margin-left: 5%;"
          @click="obtenerToken()">
          No
        </v-btn>

      </v-row>

      <br>
      <br>

      <v-form ref="form" v-model="valid">

        <v-col v-if="pagar != false">
          <v-checkbox style="max-width: 620px; margin-left: 5%;" v-model="conditions" :rules="conditionsRules"
            label="AUTORIZACIÓN PARA TRATAMIENTO DE DATOS PERSONALES: Autorizo que mi información personal sea recopilada, almacenada, tratada y divulgada por Cencosud Scotiabank, según la normativa vigente y conforme al Acuerdo de Privacidad contenido en www.seguroscencosud.cl y www.tarjetacencosud.cl."
            required dense>
          </v-checkbox>
        </v-col>

      </v-form>

      <v-col v-if="pagar != false">

        <br v-if="status == false">

        <b v-if="status == false" class="valor"> Puedes pagar tu SOAP con: </b>

        <v-radio-group v-if="status == false" autofocus v-model="switchFormaPago" :rules="confirmRules" required row>
          <v-radio style="margin: auto;" value='true' off-icon on-icon><template v-slot:label>
              <div class="forecast-selector">
                <v-img contain :src="webPayLogo" style="width: 300px; height: 135px; margin: auto;" />
                <br>
              </div>
            </template></v-radio>
        </v-radio-group>

        <form v-if="token" :action="webPayUrl" method="POST" ref="form_payment">
          <input type="hidden" name="token_ws" :value="token" />
          <v-btn
            style="width: 310px; height: 64px; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: 0px 0px 10px #2D387C; border: 1px solid #DEDEDE; border-radius: 30px;
                  opacity: 1; text-align: center; font: normal normal normal 22px/27px Helvetica Neue; letter-spacing: 0.66px; color: #3333CC; text-transform: uppercase; opacity: 1;"
            type="submit" value="Pagar" @click="submitPago">
            Pagar
          </v-btn>
        </form>

      </v-col>

    </div>
  </v-container>
</template>

<script>
import RutUtils from "../utils/rut.utils";
import webPayLogo from "@/assets/images/webpay.png";
import ShieldService from "../services/shield.service";
import swal from 'sweetalert';

export default {

  name: "PlanForm",
  props: [],
  data: () => ({
    status: false,
    screen: window.screen.width,
    typeCliente: null,
    keygen: null,
    patentes: [],
    priceTotal: 0,
    propietarioData: {
      rutPropietario: null,
      propietario: null,
    },
    planId: null,
    webPaySelect: true,
    type: null,
    token: null,
    pagar: false,
    conditions: null,
    loadPuntos: true,
    loadLogin: false,
    dialog: false,
    valid: false,
    switchFormaPago: null,
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    webPayLogo,
    webPayUrl: process.env.VUE_APP_WEBPAY_URL,
    newRut: "",
    dialogDelete: false,
    dialog: false,
    editedIndex: -1,
    editedItem: {
      patenteNumber: '',
      typeVehicle: '',
      modelVehicle: '',
      personEmail: '',
      price: 0,
    },
    defaultItem: {
      patenteNumber: '',
      typeVehicle: '',
      modelVehicle: '',
      personEmail: '',
      price: 0,
    },
    headers: [
      {
        text: 'Patente',
        align: 'start',
        sortable: false,
        value: 'patenteNumber',
        align: 'center',
      },
      { text: 'Tipo de Vehículo', value: 'typeVehicle', align: 'center', },
      { text: 'Modelo', value: 'modelVehicle', align: 'center', },
      { text: 'Correo', value: 'personEmail', align: 'center', },
      { text: 'Valor SOAP', value: 'price', align: 'center', },
      { text: 'Eliminar', value: 'actions', sortable: false, align: 'center', },
    ],
    confirmRules: [(v) => !!v || "Selecciona una opción"],
    rutRules: [
      (v) => !!v || "Rut de quién paga es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
    ],
    conditionsRules: [
      (v) => !!v || "Las condiciones deben ser aceptadas",
    ]
  }),
  async mounted() {
    this.propietarioData.propietario = 'true'
    this.pagar = false
    const query = this.$route.query;

    if (query.keygen) {
      this.keygen = query.keygen
    }

    this.patenteData = await ShieldService.getCars(this.keygen).then((response) => {
      if (response.status == 'ok') {
        response.data.forEach(car => {
          this.patentes.push(car)
          this.priceTotal = this.priceTotal + car.price
        });
      } else if (response.status == 'failed') {
        swal({
          title: "No posee SOAP's en su carrito!",
          text: "Será redirigido al inicio de la plataforma",
          icon: "info",
          button: "Ok",
        }).then(() => {
          window.location.href = process.env.VUE_APP_DOMINIO + '/inicio'
        });
      }
    })

  },

  methods: {
    deleteItem(item) {
      this.editedIndex = this.patentes.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    async deleteItemConfirm() {
      await ShieldService.deleteCar(this.patentes[this.editedIndex]._id).then((response) => {
        if (response == 'Delete car') {
          this.patentes.splice(this.editedIndex, 1)
          this.closeDelete()
        }
      })
      location.reload();
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    back() {
      this.$emit("back");
    },
    validate(plan) {
      this.$emit("next-step", 2, plan);
    },
    NewCarro() {
      this.token = null,
        window.location.href = process.env.VUE_APP_DOMINIO + 'inicio?newCar=true';
    },
    async getToken() {
      this.planId = localStorage.getItem('planId')
      var webPayToken = await ShieldService.create(this.keygen, this.planId);
      if (webPayToken.status == 'ok') {
        this.token = webPayToken.data.token;
      } else {
        swal({
          title: "UPS! Algo salió mal.",
          text: "Vuelta a internarlo nuevamente.",
          icon: "info",
          button: "Ok",
        }).then(() => {
          window.location.href = process.env.VUE_APP_DOMINIO + '/inicio'
        });
      }
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    async changeRut(rut) {
      this.propietarioData.rutPropietario = RutUtils.formatRut(rut);
      this.newRut = RutUtils.formatRut(rut);

      await ShieldService.updatePagador(this.keygen, this.propietarioData);
    },
    async submitPago(event) {
      event.preventDefault();

      if (this.$refs.form.validate()) {
        this.$refs.form_payment.submit();
        return;
      }
    },
    async obtenerToken() {
      this.planId = localStorage.getItem('planId')
      var webPayToken = await ShieldService.create(this.keygen, this.planId);
      if (webPayToken.status == 'ok') {
        this.token = webPayToken.data.token;
        this.pagar = true
      } else {
        swal({
          title: "UPS! Algo salió mal.",
          text: "Vuelta a internarlo nuevamente.",
          icon: "info",
          button: "Ok",
        }).then(() => {
          window.location.href = process.env.VUE_APP_DOMINIO + '/inicio'
        });
      }
      this.webPaySelect = false
    },
  },
};
</script>


<style lang="css" scoped>
.titulo {
  color: rgb(126, 43, 137);
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0px;
  font-family: Nunito;
  text-align: center;
  line-height: 1.2;
}

.subtitulo {
  font-family: Nunito;
  width: 280px;
  text-align: center;
  margin: 15px auto auto;
  line-height: 1.2;
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#app>div.v-dialog__content.v-dialog__content--active>div>div>div>div>div:nth-child(1)>button {
  height: 36px;
  min-width: 64px;
  padding: 0 16px;
  background-color: rgb(19, 65, 105);
}

.precio {
  width: 110px;
  height: 40px;
  text-align: center;
  font: normal normal normal 35px/41px Helvetica Neue;
  letter-spacing: 0.53px;
  color: #FFB600;
  text-transform: uppercase;
  opacity: 1;
}

.patenteNumber {
  width: 138px;
  height: 40px;
  text-align: center;
  font: Arial;
  font-size: 35px;
  letter-spacing: 0.53px;
  color: #4E4E4E;
  text-transform: uppercase;
  opacity: 1;
}

.valor {
  width: 56px;
  height: 21px;
  text-align: center;
  font: normal normal medium 17px/21px Helvetica Neue;
  letter-spacing: 0.26px;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
}

.condicion {
  width: 56px;
  height: 21px;
  text-align: center;
  font: Arial;
  font-size: 14px;
  letter-spacing: 0.26px;
  color: #0069B6;
  text-transform: uppercase;
  opacity: 1;
}

.patente {
  width: 76px;
  height: 21px;
  font: Arial;
  font-size: 17px;
  letter-spacing: 0.26px;
  color: #4E4E4E;
  text-transform: uppercase;
  opacity: 1;
}

.webpay {
  width: 80%;
  height: 206px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
}
</style>