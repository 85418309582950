import axios from 'axios'

const { VUE_APP_SENNA_URL } = process.env

export default class SoapService {

    static async consultaSoap(patente) {
        try {
            const soapRes = await axios.post(`${VUE_APP_SENNA_URL}/car/getconsulta`, {
                patente: patente,
            });
            return soapRes.data;
        } catch (error) {
            return error
        }
    }
}