<template>
  <v-container
    style="justify-content: center; height: 100%; background: #FFFFFF 0% 0% no-repeat padding-box; opacity: 1;">

    <div class="text-center">

      <b class="gracias"> ¡MUCHAS GRACIAS POR COMPRAR TU SOAP CON NOSOTROS!
      </b>

      <br>
      <br>
      <br>

      <b class="correo">
        ¡Tu póliza fue enviada a tu correo!
      </b>

      <br>
      <br>

      <template v-if="!loadingData">
        <v-data-table :footer-props="{ 'items-per-page-text': '' }" :headers="headers" :items="patentes"
          :items-per-page="5" sort-by="price" class="elevation-1">
          <template v-slot:item.actions="{ item }">
            <v-btn dark style="background-color: #3333CC;" @click="DescargarArchivo(item)">
              Descargar
            </v-btn>
          </template>
          <template v-slot:item.price="{ item }">
            <label style="margin:auto">{{ formatPrice(item.price) }}</label>
          </template>
        </v-data-table>
      </template>

      <div v-if="loadingData" class="text-center">
        <br>
        <br>
        <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
        Cargando
      </div>

    </div>

  </v-container>
</template>

<script>
import webPayLogo from "@/assets/images/webpay.png";
import ShieldService from "../services/shield.service";
import swal from 'sweetalert';

export default {
  name: "PlanForm",
  data: () => ({
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    screen: window.screen.width,
    descargar: null,
    webPayLogo,
    token: null,
    keygen: null,
    patentes: null,
    loadingData: true,
    headers: [
      {
        text: 'Patente',
        align: 'start',
        sortable: false,
        value: 'patenteNumber',
        align: 'center',
      },
      { text: 'Tipo de Vehículo', value: 'typeVehicle', align: 'center', },
      { text: 'Modelo', value: 'modelVehicle', align: 'center', },
      { text: 'Correo', value: 'personEmail', align: 'center', },
      { text: 'Valor SOAP', value: 'price', align: 'center', },
      { text: 'Descargar', value: 'actions', sortable: false, align: 'center', },
    ],
  }),
  async mounted() {
    const query = this.$route.query;
    if (this.screen > 800) {
      this.descargar = 'descargar'
    } else if (this.screen <= 800) {
      this.descargar = 'descargar2'
    }
    if (query.reload) {
      swal({
        title: "Intente nuevamente!",
        text: "Ahora puedes descargar tu póliza",
        icon: "info",
        button: "Ok",
      })
    }

    setTimeout(() => {
      this.getInfo()
    }, 3000);

  },
  methods: {
    back() {
      this.$emit("back");
    },
    validate(plan) {
      this.$emit("next-step", 2, plan);
    },
    DescargarArchivo(item) {
      try {
        const url = window.URL.createObjectURL(new Blob([new Uint8Array(item.pdf.data).buffer]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `vehiculo-${item.patenteNumber}.pdf`);
        document.body.appendChild(link);
        link.click();

      } catch (error) {
        console.log(error)
        window.location.href = window.location + '&reload=true'
      }
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    async getInfo() {
      const query = this.$route.query;
      if (query) {
        if (query.success == 'true' && query.token) {
          this.token = query.token
          this.patentes = await ShieldService.getToken(this.token)
          this.loadingData = false
        } else if (query.success == 'true' && query.keygen) {
          this.keygen = query.keygen
          this.patentes = await ShieldService.getKeygen(this.keygen)
          this.loadingData = false
        }
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.gracias {
  text-align: center;
  font: normal normal normal 25px/30px Helvetica Neue;
  letter-spacing: 0px;
  color: #573C81;
  text-transform: uppercase;
  opacity: 1;
}

.correo {
  text-align: center;
  font: normal normal normal 20px/24px Helvetica Neue;
  letter-spacing: 0px;
  color: #2D387C;
}

.email {
  width: 230px;
  height: 23px;
  text-align: center;
  font-family: "Scotia_Legal";
  font-size: 20px;
  letter-spacing: 0px;
  color: #D74E80;
  text-transform: uppercase;
}

.preferencia {
  width: 416px;
  height: 17px;
  text-align: center;
  font-family: "Scotia_Legal";
  font-size: 15px;
  letter-spacing: 0px;
  color: #5C5C5C;
  opacity: 1;
}

.webpay {
  width: 482px;
  height: 206px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  opacity: 1;
}

.descargar {
  margin-left: 37%;
}

.descargar2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30%;
}
</style>