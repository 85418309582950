<template>
  <div>

    <v-form v-if="!loadCarCreate" ref="form" v-model="valid"
      style="height: 100%; background: #FFFFFF 0% 0% no-repeat padding-box; opacity: 1;">

      <v-row justify="center">
        <v-dialog v-model="dialog" width="600px">
          <v-card>
            <v-card-title style="background-color: red; color: white;">
              <span class="text-h5">TÉRMINOS Y CONDICIONES</span>
            </v-card-title>
            <v-card-text style="text-align: justify;">
              <br>
              <h2>TÉRMINOS Y CONDICIONES DEL SOAP ELECTRÓNICO SOUTHBRIDGE COMPAÑÍA DE SEGUROS GENERALES S.A.</h2>
              <br>
              La compañía que cubre el riesgo es Southbridge Compañía de Seguros Generales S.A. El detalle de las
              condiciones y exclusiones están contenidas en la póliza incorporada al depósito de pólizas de la CMF bajo el
              código POL 3 2013 0487 y en las condiciones particulares del contrato. La contratación de este seguro cumple
              con la Circular 1.864 del 21.01.08 de la CMF., que regula la venta del SOAP por Internet. Los datos o
              antecedentes ingresados son de exclusiva responsabilidad del contratante asumiendo las consecuencias
              derivadas de errores o inexactitudes de información.
              <br>
              <br>
              <h2>Veracidad de la Información</h2>
              <br>
              Los datos o antecedentes ingresados tanto del vehículo como del propietario son de exclusiva responsabilidad
              del solicitante y debe concordar con los que se registran en el Registro Nacional de Vehículos Motorizados,
              los cuales puede obtener del padrón o certificado de dominio del vehículo. Cualquier error o inexactitudes
              de información será exclusiva responsabilidad del solicitante, liberando a la compañía de toda
              responsabilidad.
              <br>
              <br>
              <h2>Devolución o Anulaciones</h2>
              <br>
              El seguro SOAP ELECTRÓNICO no permite devoluciones ni anulaciones.
              <br>
              <br>
              <h2>Forma de Pago</h2>
              <br>

              Southbridge Compañía de Seguros Generales S.A. no tiene ningún convenio con los Bancos para el pago en
              cuotas de pólizas de seguro obligatorio. La compañía no se hace responsable por los intereses que su banco
              le pueda cobrar al ofrecerle el pago en cuotas. Infórmese sobre los intereses que le cobra su banco,
              comunicándose directamente con su ejecutivo.
              <br>
              <br>
              <h2>Información de los Vehículos</h2>
              <br>
              Según la normativa vigente, los datos del vehículo no pueden ser modificados a través de este medio, en caso
              de que estos no correspondan o tenga dudas respecto de la exactitud de ellos comuníquese a nuestro centro de
              soporte Web al 800 391 300 (02 2826 8333, desde móviles), o acérquese a nuestra casa matriz ubicada en
              Presidente Riesco 5335 of 15, Las Condes, Santiago.
              <br>
              <br>
              <h2>Despacho a Domicilio</h2>
              <br>
              Por normativa de la CMF, circular Nº 1864, si el contratante así lo desea, y así lo expresa al momento de la
              contratación, Southbridge Compañía de Seguros Generales S.A. deberá enviar el certificado SOAP a su
              domicilio, el que deberá ser despachado por un servicio de correo fehaciente y expedito dentro de los 3 días
              hábiles siguientes a cursada la solicitud. El despacho a domicilio tiene un recargo de $5.000 pesos en la
              Región Metropolitana y de $8.000 en otras regiones. El certificado que recibirá por correo postal es una
              impresión del mismo SOAP electrónico, tal como la que el cliente puede imprimir con una impresora particular
              común. Se enviarán todos los SOAP a un mismo domicilio.
              <br>
              <br>
              <h2>Consultas</h2>
              <br>
              Si tiene dudas o necesita apoyo en su proceso de contratación del seguro llámenos a nuestro centro de
              soporte Web al 800 391 300 (02 2826 8333, desde móviles). El horario de atención es: de lunes a viernes: de
              09:00 hrs. a 18:00 hrs.
            </v-card-text>
            <v-card-actions>
              <v-btn elevation="0" text @click="dialog = false"
                style="background-color: red; color: white; margin-left: 80%;">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <br>

      <v-row justify="center">
        <v-dialog v-model="dialogEdit" width="600px">
          <v-card>
            <v-card-title style="background-color: red; color: white;">
              <span v-if="index == 1" class="text-h5">Editar: Marca de vehículo</span>
              <span v-if="index == 2" class="text-h5">Editar: Modelo de vehículo</span>
              <span v-if="index == 3" class="text-h5">Editar: Número de motor</span>
            </v-card-title>
            <v-card-text style="text-align: justify;">
              <br>

              <v-form ref="formEdit" v-model="validEdit">

                <!-- <div v-if="index == 1">
                  <label class="labelName2">Marca de vehículo:</label>
                  <v-text-field :class="labelsInf" v-model="newLabel" label="Marca de vehículo: " :rules="marcaRules"
                    required filled solo>
                  </v-text-field>
                </div>

                <div v-if="index == 2">
                  <label class="labelName2">Modelo de vehículo</label>
                  <v-text-field :class="labelsInf" v-model="newLabel" label="Modelo de vehículo " :rules="modeloRules"
                    required filled solo>
                  </v-text-field>
                </div> -->

                <div v-if="index == 3">
                  <label class="labelName2">Número de motor:</label>
                  <v-text-field :class="labelsInf" v-model="newLabel" label="Número de motor: " :rules="motorRules"
                    required filled solo>
                  </v-text-field>
                </div>

              </v-form>

            </v-card-text>
            <v-card-actions>
              <br>
              <br>
              <br>
              <br>
              <v-btn elevation="0" text @click="saveItem(index)"
                style="background-color: red; color: white; ">
                Guardar
              </v-btn>
              <v-btn elevation="0" text @click="dialogEdit = false" style="background-color: red; color: white; ">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <br>

      <v-container :class="recuadro">

        <v-col class="rectangulo">

          <b class="titulo2">
            Datos del vehículo
          </b>

          <br>
          <hr style="border: 1px solid #C1C1C1; opacity: 1;">
          <br>
          <label class="subtitulo2">
            Los datos del vehículo se cargarán automáticamente, de no ser así, complételos. Todos los datos son
            obligatorios
          </label>

          <br>
          <br>

          <v-col :class="columnas">

            <div>
              <label class="labelName">Número patente:</label>
              <v-text-field :disabled="carData.patente ? 'disabled' : ''" :class="labelsSup" v-model="carData.patente"
                label="Número patente: " :rules="patenteRules" required filled solo>
              </v-text-field>
            </div>

            <div>
              <label class="labelName2">Año del vehículo:</label>
              <v-text-field :disabled="carData.year ? 'disabled' : ''" :class="labelsInf" v-model="carData.year"
                label="Año del vehículo: " :rules="yearRules" required filled solo>
              </v-text-field>
            </div>

          </v-col>

          <v-col :class="columnas">

            <div>
              <label class="labelName">Tipo de vehículo:</label>
              <v-text-field :disabled="carData.type ? 'disabled' : ''" :class="labelsSup" v-model="carData.type"
                label="Tipo de vehículo: " :rules="typeRules" required filled solo return-object>
              </v-text-field>
            </div>

            <div>
              <label class="labelName2">Marca de vehículo:</label>
              <v-text-field :disabled="carData.marca ? 'disabled' : ''" :class="labelsInf" v-model="carData.marca"
                label="Marca de vehículo: " :rules="marcaRules" required filled solo return-object>
              </v-text-field>
              <!-- <v-btn @click="setItem(1)"><v-icon>mdi-pencil</v-icon></v-btn> -->
            </div>

          </v-col>

          <v-col :class="columnas">

            <div>
              <label class="labelName">Modelo de vehículo:</label>
              <v-text-field :disabled="carData.model ? 'disabled' : ''" :class="labelsSup" v-model="carData.model"
                label="Modelo de vehículo: " :rules="modeloRules" required filled solo return-object>
              </v-text-field>
              <!-- <v-btn @click="setItem(2)"><v-icon>mdi-pencil</v-icon></v-btn> -->
            </div>

            <div v-if="!campoMotorNull">
              <label class="labelName2">Número de motor:</label>
              <v-text-field disabled :class="labelsInf" v-model="carData.motor"
                label="Número de motor: " :rules="motorRules" required filled solo>
              </v-text-field>
              <!-- <v-btn @click="setItem(3)"><v-icon>mdi-pencil</v-icon></v-btn> -->
            </div>

            <div v-if="campoMotorNull">
              <label class="labelName2">Número de motor:</label>
              <v-text-field :class="labelsInf" v-model="carData.motor"
                label="Número de motor: " :rules="motorRules" required filled solo>
              </v-text-field>
              <!-- <v-btn @click="setItem(3)"><v-icon>mdi-pencil</v-icon></v-btn> -->
            </div>

          </v-col>

        </v-col>

        <br>

        <v-col class="rectangulo">

          <b class="titulo2">
            Datos del propietario
          </b>
          <hr style="border: 1px solid #C1C1C1; opacity: 1;">
          <br>
          <label class="subtitulo2">
            Los datos del propietario se cargarán automáticamente, de no ser así, complételos. Todos los datos son
            obligatorios
          </label>

          <br>
          <br>

          <v-col :class="columnas">

            <div>
              <label class="labelName">Rut:</label>
              <v-text-field v-model.lazy="newRut" label="RUT" required filled solo :class="labelsSup" :rules="rutRules"
                @change="changeRut" hide-details="auto">
              </v-text-field>
            </div>

            <div>
              <label class="labelName2">Nombres:</label>
              <v-text-field v-model="propietarioData.nombres" label="Nombres" :class="labelsInf" :rules="namesRules"
                required filled solo>
              </v-text-field>
            </div>

          </v-col>

          <v-col :class="columnas">

            <div>
              <label class="labelName">Primer apellido:</label>
              <v-text-field v-model="propietarioData.firtlastname" :class="labelsSup" label="Primer apellido: "
                :rules="surnamePRules" required filled solo>
              </v-text-field>
            </div>

            <div>
              <label class="labelName2">Segundo apellido:</label>
              <v-text-field v-model="propietarioData.secondlastname" :class="labelsInf" label="Segundo apellido: "
                :rules="surnameMRules" required filled solo>
              </v-text-field>
            </div>

          </v-col>

          <v-col :class="columnas">

            <div>
              <label class="labelName">Correo electrónico:</label>
              <v-text-field v-model="propietarioData.email" :rules="emailRules" :class="labelsSup"
                label="Correo electrónico: " required filled solo>
              </v-text-field>
            </div>

            <div>
              <label class="labelName2">Número celular:</label>
              <v-text-field v-model="propietarioData.phoneNumber" :class="labelsInf" :rules="phoneRules" :counter="8"
                prefix="+569" required filled solo>
              </v-text-field>
            </div>

          </v-col>

        </v-col>

      </v-container>

      <div class="rectangulo2 text-center">

        <br>
        <b class="valor"> VALOR COTIZADO
        </b>

        <br>

        <div style="display: flex;">
          <hr style="width: 147px; height: 0px; border: 1px solid #D0D0D0; opacity: 1; margin:auto;"><b class="precio">{{
            formatPrice(carData.price)
          }}</b>
          <hr style="width: 147px; height: 0px; border: 1px solid #D0D0D0; opacity: 1; margin:auto;">
        </div>

        <v-btn
          style="width: 310px; height: 64px; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: 0px 0px 10px #2D387C; border: 1px solid #DEDEDE; border-radius: 30px;
                                    opacity: 1; text-align: center; font: normal normal normal 22px/27px Helvetica Neue; letter-spacing: 0.66px; color: #3333CC; text-transform: uppercase; opacity: 1;"
          @click="validate()">
          Continuar
        </v-btn>

        <br>
        <p></p>

        <b class="textTerminos">
          Al continuar estás aceptando nuestros <b><a class="terminosCondiciones" @click="dialog = true">términos y
              condiciones</a></b></b>

      </div>

    </v-form>

    <div v-if="loadCarCreate" class="text-center">
      <br>
      <br>
      <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
      Cargando
    </div>

  </div>
</template>

<script>

import ShieldService from "../services/shield.service";
import RutUtils from "../utils/rut.utils";
import keygen from 'keygen';
import swal from 'sweetalert';

export default {
  name: "InitForm",
  props: ["carData", "propietarioData", "newCar"],
  data: () => ({
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    campoMotorNull: false,
    loadCarCreate: false,
    newRut: '',
    keygen: null,
    typeCliente: null,
    typeClienteRuta: null,
    dialog: false,
    dialogEdit: false,
    newLabel: null,
    index: null,
    recuadro: null,
    columnas: null,
    labelsSup: null,
    labelsInf: null,
    screen: window.screen.width,
    marcaVehicle: [],
    modeloVehicle: [],
    planId: null,
    rutRules: [
      (v) => !!v || "El rut es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no valido",
    ],
    emailRules: [
      (v) => !!v || "El correo electrónico es requerido",
      (v) => /.+@.+/.test(v) || "Ingrese un correo válido",
    ],
    patenteRules: [
      (v) => !!v || "El número de patente es requerido",
    ],
    yearRules: [
      (v) => !!v || "El año del vehículo es requerido",
    ],
    typeRules: [
      (v) => !!v || "El tipo de vehículo es requerido",
    ],
    marcaRules: [
      (v) => !!v || "La marca del vehículo es requerida",
    ],
    modeloRules: [
      (v) => !!v || "El modelo de vehículo es requerido",
    ],
    motorRules: [
      (v) => !!v || "El número del motor es requerido",
    ],
    namesRules: [
      (v) => !!v || "Debe ingresar su nombre",
    ],
    surnamePRules: [
      (v) => !!v || "Debe ingresar su apellido paterno",
    ],
    surnameMRules: [
      (v) => !!v || "Debe ingresar su apellido materno",
    ],
    phoneRules: [
      (v) => !!v || "El número telefónico es requerido",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
      (v) => (!!v && v.length == 8) || "Ingrese un número válido",
    ],
    conditionsRules: [
      (v) => !!v || "Debe estar de acuerdo con los términos y condiciones",
    ],
  }),
  methods: {
    async validate() {
      const query = this.$route.query;
      if (this.$refs.form.validate()) {
        this.loadCarCreate = true
        var CarRes;
        switch (query.type) {
          case 'soap-clientes-segurofacil':
            this.typeCliente = 'clientes'
            break;
          default:
            break;
        }

        if (this.newCar == false) {
          var key = keygen.url(keygen.medium)
          localStorage.setItem('keygen', key)
          this.planId = localStorage.getItem('planId')
          this.keygen = localStorage.getItem('keygen')
          CarRes = await ShieldService.newCar(this.carData.id, this.propietarioData, key, this.planId, this.typeCliente, this.campoMotorNull, this.carData.motor);
        } else if (this.newCar == true) {
          this.planId = localStorage.getItem('planId')
          this.keygen = localStorage.getItem('keygen')
          CarRes = await ShieldService.newCar(this.carData.id, this.propietarioData, this.keygen, this.planId, this.typeCliente, this.campoMotorNull, this.carData.motor);
        }
        window.location.href = process.env.VUE_APP_DOMINIO + 'resumen' + '?type=' + query.type + '&keygen=' + this.keygen
      }
    },
    changeRut(rut) {
      this.propietarioData.rut = RutUtils.formatRut(rut);
      this.newRut = RutUtils.formatRut(rut);
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    setItem(index) {
      this.index = index
      this.newLabel = null
      this.dialogEdit = true
    },
    saveItem(index) {

      if (this.$refs.formEdit.validate()) {
        swal({
          title: "Verifique la información ingresada!",
          text: "¿Está seguro de editar el campo seleccionado?",
          icon: "info",
          buttons: true,
          dangerMode: true,
        }).then((willEdit) => {
          if (willEdit) {
            switch (index) {
              case 1:
                this.carData.marca = this.newLabel
                break;
              case 2:
                this.carData.model = this.newLabel
                break;
              case 3:
                this.carData.motor = this.newLabel
                break;
              default:
                break;
            }
            swal({
              title: "Se ha editado el campo",
              icon: "success"
            });
            this.dialogEdit = false
          } else {
            swal({
              title: "No se ha editado el campo",
              icon: "error"
            });
          }
        });
      }
    }
  },
  mounted() {
    if(this.carData.motor == null || this.carData.motor.length < 2){
      this.campoMotorNull = true
    }
    // document.body.style.zoom = "90%";
    this.changeRut(this.propietarioData.rut)
    if (this.screen > 800) {
      this.labelsSup = 'labelsSup'
      this.labelsInf = 'labelsInf'
      this.columnas = 'columnas'
      this.recuadro = 'recuadro'
    } else if (this.screen <= 800) {
      this.labelsSup = 'labelsSup2'
      this.labelsInf = 'labelsInf2'
      this.columnas = 'columnas2'
      this.recuadro = ''
    }
  }
};
</script>

<style lang="css" scoped>
.titulo2 {
  text-align: left;
  font: normal normal medium 20px/25px Helvetica Neue;
  letter-spacing: 0.3px;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
}

.subtitulo2 {
  text-align: left;
  font: normal normal normal 15px/17px Helvetica Neue;
  letter-spacing: 0.23px;
  color: #573C81;
  opacity: 1;
}

.labelName {
  text-align: left;
  font: normal normal medium 12px/15px Helvetica Neue;
  letter-spacing: 0.18px;
  color: #2D387C;
  opacity: 1;
}

.labelName2 {
  text-align: left;
  font: normal normal medium 12px/15px Helvetica Neue;
  letter-spacing: 0.18px;
  color: #2D387C;
  opacity: 1;
  margin-left: 4%;
}

.valor {
  text-align: center;
  font: normal normal medium 12px/15px Helvetica Neue;
  letter-spacing: 0.18px;
  color: #3333CC;
  text-transform: uppercase;
  opacity: 1;
}

.precio {
  text-align: center;
  font: Arial;
  font-size: 25px;
  letter-spacing: 0.38px;
  color: #EF7C00;
  text-transform: uppercase;
  opacity: 1;
  font-size: 25px;
}

.textTerminos {
  text-align: center;
  font: normal normal normal 15px/17px Helvetica Neue;
  letter-spacing: 0px;
  color: #2D387C;
}

.terminosCondiciones {
  text-align: center;
  text-decoration: underline;
  font: normal normal normal 15px/17px Helvetica Neue;
  letter-spacing: 0px;
  color: #3333CC;
}

.labelsSup {
  width: 100%;
  height: 58px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
  margin-left: -2%
}

.labelsSup2 {
  width: 100%;
  height: 58px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
}

.labelsInf {
  width: 100%;
  height: 58px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
  margin-left: 2%
}

.labelsInf2 {
  width: 100%;
  height: 58px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
}

.rectangulo {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  opacity: 1;
  margin-left: 1%;
  margin-right: 1%;
  max-width: 500px;
}

.rectangulo2 {
  max-width: 482px;
  height: 220px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
}

.columnas {
  display: flex;
}

.columnas2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
}

.recuadro {
  justify-content: center;
  display: flex;
}

/* .test {
  text-align: left;
  font-family: "Scotia_Bd";
  letter-spacing: 0.3px;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
}

.test2 {
  text-align: left;
  font-family: "Scotia_BdIt";
  letter-spacing: 0.3px;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
}

.test3 {
  text-align: left;
  font-family: "Scotia_Headline";
  letter-spacing: 0.3px;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
}

.test4 {
  text-align: left;
  font-family: "Scotia_It";
  letter-spacing: 0.3px;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
}

.test5 {
  text-align: left;
  font: Arial;
  letter-spacing: 0.3px;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
}


.test6 {
  text-align: left;
  font-family: "Scotia_Lt";
  letter-spacing: 0.3px;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
}

.test7 {
  text-align: left;
  font-family: "Scotia_LtIt";
  letter-spacing: 0.3px;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
}

.test8 {
  text-align: left;
  font-family: "Scotia_Rg";
  letter-spacing: 0.3px;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
} */
</style>